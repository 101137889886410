<template>
  <div id="mrf-report">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <!-- <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title> -->

      <v-container class="py-0" v-if="floatButtonFlag">
        <v-speed-dial
          v-if="floatButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
        </v-speed-dial>
      </v-container>
      <v-dialog v-model="addRecordPrompt" persistent scrollable max-width="90%">
        <committee-member-create
          :addRecordPrompt="addRecordPrompt"
          @hideDialog="hideAddRecordPrompt($event)"
          v-if="addRecordPrompt"
        ></committee-member-create>
      </v-dialog>

      <v-dialog
        v-model="editRecordPrompt"
        persistent
        scrollable
        max-width="90%"
      >
        <committee-member-edit
          :editRecordPrompt="editRecordPrompt"
          :recordData="selectedData"
          @hideEditRecordPrompt="hideEditRecordPrompt($event)"
          v-if="editRecordPrompt"
        ></committee-member-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="12" align="center">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#9370d8"
                  class="font-size-h6 my-3 mr-3 white--text"
                  @click.prevent="refreshPageData"
                >
                  Refresh
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#66cd00"
                  class="font-size-h6 my-3 mr-3 white--text"
                  @click.prevent="addRecordPrompt = true"
                >
                  Assign Member
                </v-btn>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="JciYearCode"
                  :rules="JciYearCodeRules"
                  :items="JciYearCodeOptions"
                  :loading="JciYearCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Committee</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="CommitteeCode"
                  :rules="CommitteeCodeRules"
                  :items="CommitteeCodeOptions"
                  :loading="CommitteeCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="getTableRecords"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <!-- <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              class="font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#00a4ef"
            >Refresh</v-btn>
            <v-btn
              v-if="tableOptions1.CreateButtonFlag"
              @click.prevent="addRecordPrompt = true"
              color="success"
              class="font-size-h6 px-15 py-4 my-3 mr-3 white--text"
            >Add / Modify Committee members</v-btn>
            <router-link to="/national-committees-members/rearrange">
              <v-btn
                v-if="tableOptions1.EditButtonFlag"
                color="primary"
                class="font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >Rearrange Order</v-btn>
            </router-link>
          </v-col>
        </v-row> -->

        <v-container class="py-0" v-if="!ResultFlag && !SearchFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag && !SearchFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} members found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-data-iterator
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="4"
                    >
                      <v-card raised hover ripple elevation="25">
                        <v-card-title>
                          {{ item.MonthTxt }}
                        </v-card-title>
                        <v-card-text>
                          <v-avatar size="200">
                            <img
                              :src="item.ProfilePic"
                              :alt="item.MemberFullname"
                            />
                          </v-avatar>
                          <h3>{{ item.MemberFullname }}</h3>
                          <h3 class="text-primary">
                            {{ item.DesignationName }}
                          </h3>
                          <h4
                            style="color: green"
                            v-if="RoleId == 1 || RoleId == 2 || RoleId == 4"
                          >
                            {{ item.MembershipId }}
                          </h4>
                          <h4 style="color: red">{{ item.LomName }}</h4>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#008000"
                            class="font-size-h6 white--text"
                            @click="editData(item, e)"
                            v-if="item.DesignationId != 4 && editFlag"
                          >
                            <v-icon>mdi-pencil</v-icon> Edit
                          </v-btn>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#ff0000"
                            class="font-size-h6 white--text"
                            @click="deleteConfirmAlert(item, e)"
                            v-if="DeleteFlag"
                          >
                            <v-icon>mdi-delete</v-icon> Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import CommitteeMemberCreate from "@/view/pages/erp/committee-members/CommitteeMembersCreate.vue";
import CommitteeMemberEdit from "@/view/pages/erp/committee-members/CommitteeMembersEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { CommitteeMemberCreate, CommitteeMemberEdit },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      DeleteFlag: true,

      floatButtonFlag: false,

      OverlayLoadingFlag: false,
      OverlayLoadingText: "",

      rows: [],
      tableSelectFlag1: false,
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      editFlag: true,
      selectedData: {},

      Member: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      CommitteeCodeRules: [(v) => !!v || "Committee is required"],
      CommitteeCode: "",
      CommitteeCodeOptions: [],
      CommitteeCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      RoleId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        this.RoleId = RoleId;

        this.getYearCodeOptions();
        this.getCommitteeOptions();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    CommitteeCodeOptions: function () {
      console.log("watch CommitteeCodeOptions");
      this.CommitteeCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "committee_members",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      var CurrentYearId = this.YearCode;
      console.log("CurrentYearId=" + CurrentYearId);
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getCommitteeOptions() {
      console.log("getCommitteeOptions called");
      this.CommitteeCodeOptionsLoading = true;
      var selectbox1_source = "CommitteeCode";
      var selectbox1_destination = "CommitteeCodeOptions";
      var selectbox1_url = "api/committee/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        // this.LoadingFlag = true;
        // this.SearchFlag = true;
        // this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/lists";

        var upload = {
          UserInterface: 7,
          OrganizationTypeId: 1,
          YearCode: this.JciYearCode,
          CommitteeCode: this.CommitteeCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var ResultFlag = false;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.SearchFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              var TableData = records.TableData;
              var n1 = TableData.length;
              console.log({ n1 });
              thisIns.rows = TableData;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableSelectFlag1 = records.TableSelectFlag;
              thisIns.DeleteFlag = records.TableDeleteFlag;
              ResultFlag = n1 > 0 ? true : false;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
            this.ResultFlag = ResultFlag;
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ResultFlag = true;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.editRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      if (flag) {
        this.getTableRecords();
      }
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Member Name <b> " + tr.MemberFullname + "</b>";
        htmlTxt += "<br/> Designation <b> " + tr.DesignationName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");

      var YearId = tr.YearId;
      var ZoneId = tr.ZoneId;
      var LomId = tr.LomId;
      var MemberId = tr.MemberId;
      var DesignationId = tr.DesignationId;
      var YearwiseOfficesHeldId = tr.YearwiseOfficesHeldId;
      console.log(
        "YearId=" +
          YearId +
          "ZoneId=" +
          ZoneId +
          "LomId=" +
          LomId +
          "MemberId=" +
          MemberId +
          "DesignationId=" +
          DesignationId +
          "YearwiseOfficesHeldId=" +
          YearwiseOfficesHeldId
      );

      if (YearwiseOfficesHeldId != "" || YearwiseOfficesHeldId != undefined) {
        this.ProgressFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/member-delete";

        var upload = {
          UserInterface: 1,
          OrganizationType: 1,
          Year: YearId,
          Zone: ZoneId,
          Lom: LomId,
          Member: MemberId,
          Designation: DesignationId,
          YearwiseOfficesHeld: YearwiseOfficesHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressFlag = false;
            thisIns.toast("error", error);
          });
      } else {
        var message = "YearwiseOfficesHeld Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>